@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'reset';
@import 'scrollbar';

// 1px ===  1rem
html,
body {
  position: relative;

  background-color: #000000;

  font-size: 0.0520836vw;
  @media screen and (max-width: $sm) {
    font-size: 0.3125vw;
  }
}

body {
  min-height: $min;

  color: #ffffff;
  background-color: #000000;
  overflow-x: hidden;
  font-family: montserrat, sans-serif;

  @media screen and (max-width: $sm) {
    min-height: auto;
  }
  max-width: 100vw;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* Работает в Firefox */
* {
  scrollbar-width: 8rem;
}

body {
  scrollbar-color: darken($lavand, 20%) #000000;
}

/* Работает в Chrome, Edge и Safari */
body::-webkit-scrollbar {
  width: 8rem;
}

body::-webkit-scrollbar-track {
  background: #000000; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  border: none; /* creates padding around scroll thumb */
  border-radius: 20rem; /* roundness of the scroll thumb */
  background-color: darken($lavand, 20%); /* color of the scroll thumb */
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;

  line-height: 0;
}

a {
  text-decoration: none;

  color: inherit;
}

.visually-hidden {
  position: absolute;

  overflow: hidden;
  clip: rect(0 0 0 0);

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  white-space: nowrap;

  border: 0;

  clip-path: inset(100%);
}

.br-desk {
  display: block;
}

.mobile {
  display: none;
}

.accent-lavand {
  color: $lavand;
}

.accent-cyan {
  color: $cyan;
}

.down {
  position: relative;
  margin: 0 auto;
  z-index: 1000;

  display: block;

  box-sizing: content-box;
  width: 38.788rem;
  height: 46.545rem;
  padding: 46.545rem 46.545rem 0;

  &:hover {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 65%;
      transform: translate(-50%, -50%);
      width: 40%;
      height: 40%;
      background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
      filter: blur(25rem);
      box-shadow: 0 0 96rem rgba(#ffffff, 0.8);
    }
  }

  @media screen and (max-width: $lg) {
    &:hover {
      &::before {
        width: 20%;
        height: 20%;
      }
    }
  }
}

.title {
  margin: 0;

  text-align: center;
  text-transform: uppercase;

  color: white;

  font-family: 'montserrat', Arial, 'sans-serif';
  font-size: 60rem;
  font-weight: 900;
  line-height: 1;

  @media screen and (max-width: $lg) {
    font-size: 17.454rem;
    line-height: 21.333rem;
  }
}

.slider-btn {
  width: 64rem;
  height: 64rem;
  background-image: url('../assets/img/reviews/arrow-left.svg');
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0rem 0rem 51rem rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  pointer-events: auto;

  &--next {
    transform: scaleX(-1);
  }

  @media screen and (min-width: $lg) {
    &:hover {
      box-shadow: 0rem 0rem 51rem rgba(255, 255, 255, 0.99);
    }
  }
}

.swiper-button-disabled {
  box-shadow: none;
  opacity: 0.2;
}

.page-wrapper {
  overflow-x: hidden;
}

.promo-wrap {
  position: relative;
  z-index: 1;

  overflow: hidden;

  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;

  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 140rem), 0 100%);

  &::before {
    content: '';
    top: auto;
    bottom: -170rem;

    width: 120vw;
    height: 250rem;

    position: absolute;

    transform: rotate(-4deg);
    background: transparent;
    box-shadow: 0px -192rem 250rem rgba(0, 182, 162, 0.3);
    z-index: 0;

    pointer-events: none;
  }

  @media screen and (min-width: $lg) {
    @include retina() {
      background-image: url('../assets/img/bg-desktop-2x.jpg');
    }

    height: 100vh;
    min-height: calc(100vw / (10 / 5));

    background-image: url('../assets/img/bg-desktop-1x.jpg');
  }

  @media screen and (max-width: $lg) {
    //todo
    //height: 824rem;

    background-image: url('../assets/img/bg-mobile.jpg');
    background-size: cover;

    &::before,
    &::after {
      //content: none;
    }
  }
}

@media screen and (min-width: $lg) and (min-aspect-ratio: 1920/1000) {
  .promo-wrap {
    min-height: 1100rem;
  }
}

@media screen and (max-width: $lg) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .promo-wrap {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20rem), 0 100%);
    background-position: 0 0;

    &::before {
      bottom: -240rem;
      box-shadow: 0px -100px 116px rgba(0, 182, 162, 0.3);
    }
  }

  .down {
    display: none;
  }

  .br-desk {
    display: none;
  }
}

.wrapper {
  overflow: hidden;
}
