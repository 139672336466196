.main-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  cursor: pointer;
  opacity: 0;
  display: none;

  &.main-popup--open {
    display: block;
    animation: opacity 0.36s ease-in-out;
    opacity: 1;
  }

  &__container {
    position: relative;
    width: 1476rem;
    height: 718rem;
    background: linear-gradient(180deg, #753bbd 0%, #121420 99.87%);
    border-radius: 16rem;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 634.992rem;
      height: 410.992rem;
      background-image: url('../assets/img/arrow.png');
      background-position: bottom;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    margin-bottom: 36.992rem;
    padding-left: 200rem;
    padding-right: 200rem;
    font-weight: 900;
    font-size: 60rem;
    line-height: 72.992rem;
    text-align: center;
    text-transform: uppercase;

    span {
      color: $cyan;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding-top: 28.992rem;
    padding-bottom: 90rem;
    padding-left: 34rem;
    padding-right: 34rem;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 800rem;
    padding-bottom: 800rem;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__info {
    margin: 0;
    margin-bottom: 38.992rem;
    padding-left: 230rem;
    padding-right: 230rem;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 30rem;
    line-height: 36992rem;
    text-align: center;

    span {
      color: $cyan;
    }
  }

  &__description {
    margin: 0;
    margin-bottom: 70.992rem;
    padding-left: 278rem;
    padding-right: 278rem;
    font-weight: 500;
    font-size: 26rem;
    line-height: 32rem;
    text-align: center;
  }

  &__login {
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 508.992rem;
    height: 86.992rem;
    transform: skew(-18deg);
    color: #000000;
    box-shadow: 0 228.106rem 456.211rem rgb(0 199 177 / 50%);
    border-radius: 12.8rem;
    background-color: #00c7b1;

    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 31rem;
    line-height: 38rem;
  }

  &__btn {
    @include btn-reset;
    position: relative;
    width: 32rem;
    height: 32rem;
    margin-left: auto;
    background-color: transparent;

    &::before,
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 4rem;
      height: 100%;
      background-color: white;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

@media screen and (max-width: $lg) {
  .main-popup {
    background-color: black;

    &__overlay {
      width: 100%;
      min-height: 100vh;
      padding-top: 0;
      padding-bottom: 0;
      background: linear-gradient(180deg, #753bbd 0%, #121420 72.4%);
    }

    &__container {
      width: 100%;
      height: 100%;
      min-height: 100vh;
      border-radius: 0;

      &::before {
        width: 306rem;
        height: 198rem;
        background-image: url('../assets/img/arrow-mob.png');
      }
    }

    &__content {
      //display: block;
      min-height: 100vh;
      padding-bottom: 16rem;
      padding-top: 29.6rem;
      padding-left: 15rem;
      padding-right: 15rem;
    }

    &__info {
      margin-bottom: 23rem;
      font-size: 16rem;
      line-height: 20rem;
      padding-left: 21.3rem;
      padding-right: 21.3rem;
    }

    &__title {
      margin-bottom: 22.6rem;
      padding-left: 28rem;
      padding-right: 28rem;
      font-size: 24rem;
      line-height: 105%;
    }

    &__description {
      margin-bottom: 0;
      font-size: 12rem;
      line-height: 14.992rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }

    &__inner {
      margin-bottom: 35rem;
    }

    &__login {
      margin-bottom: 42rem;
      width: 100%;
      height: 57rem;
      font-size: 18rem;
      line-height: 22rem;
      border-radius: 5rem;
    }

    &__btn {
      width: 20rem;
      height: 20rem;
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
