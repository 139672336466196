.partners {
  position: relative;

  width: 100%;

  user-select: none;
  text-align: center;
  text-transform: uppercase;

  color: white;
  background: $background-gradient;

  font-family: 'montserrat', Arial, 'sans-serif';
  font-weight: 900;

  clip-path: polygon(0 140rem, 100% 0, 100% 100%, 0 100%);
}

.partners__container {
  padding-top: 260rem;
  padding-right: 280rem;
  padding-bottom: 170rem;
  padding-left: 280rem;

  &--mobile {
    display: none;
  }
}

.partners__title {
  margin-bottom: 80rem;
}

.partners__wrapper {
  position: relative;

  margin-bottom: 100rem;
}

.partners__names-list {
  @include list-reset;

  align-items: center;
  //padding: 0 40rem;

  margin: 0;
}

.partners__list {
  @include list-reset;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.partners__item-link {
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;

  cursor: pointer;
}

.partners__name-img {
  width: 224rem;
  height: 344rem;
  margin: 0 auto;
  margin-bottom: 42rem;
}

.partners__item-text {
  margin: 0;

  font-size: 26rem;
  line-height: 32rem;
}

.partners__box {
  position: absolute;
  z-index: 1;
  bottom: 5rem;

  width: 100%;
  height: 88rem;

  pointer-events: none;

  border: 3rem solid rgba(255, 255, 255, 0.1);
  border-radius: 10rem;
  background: rgba(255, 255, 255, 0.2);
}

.partners__name-text {
  z-index: 1;

  display: block;

  width: 284rem;
  margin: 0 10rem;
  padding-bottom: 30rem;

  font-size: 30rem;
  line-height: 1.2;
  span {
    white-space: nowrap;
  }
}

.partners__wrapper--ambassadors {
  .partners__box {
    bottom: -18rem;

    height: 112rem;
  }

  .partners__name-img {
    width: 284rem;
    height: 400rem;
    margin-bottom: 21rem;
  }

  .partners__name-text {
    padding-bottom: 1rem;
  }
}

.partners__wrapper--logo {
  .partners__name-text {
    margin-top: -86rem;
  }

  .partners__btn-wrapper {
    top: 122rem;
  }
}

.partners__btn-wrapper {
  position: absolute;
  top: 172rem;
  left: 50%;

  display: flex;
  justify-content: space-between;

  width: 1500rem;

  transform: translateX(-50%);
  pointer-events: none;
}

//.swiper {
//  padding-left: 64rem;
//  padding-right: 64rem;
//}

@media screen and (max-width: $lg) {
  .partners {
    height: auto;

    clip-path: polygon(0 20rem, 100% 0, 100% calc(100% - 20rem), 0 100%);
  }

  .partners__btn-wrapper {
    display: none;
  }

  .partners__container {
    padding-top: 56rem;
    padding-right: 20rem;
    padding-bottom: 1rem;
    padding-left: 20rem;

    &--desktop {
      display: none;
    }

    &--mobile {
      display: block;
    }
  }

  .partners__wrapper {
    position: relative;
    z-index: 1;

    margin-bottom: 50rem;

    &::before,
    &::after {
      position: absolute;
      z-index: 1;
      top: -30rem;
      left: -70rem;

      width: 100rem;
      height: 270rem;

      content: '';
      transform: rotate(-6deg);
      pointer-events: none;

      background: linear-gradient(
        180deg,
        #249db5 0%,
        rgba(22, 172, 180, 1) 100%
      );

      filter: blur(20rem);
    }

    &::after {
      z-index: 0;
      right: -70rem;
      left: auto;

      content: '';
      transform: rotate(-6deg);

      background: linear-gradient(180deg, #5d5abb 0%, #4f6bba 100%);

      filter: blur(15rem);
    }
  }

  .partners__list {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .partners__item {
    &:not(:last-child) {
      margin-bottom: 28.8rem;
    }
  }

  .partners__title {
    position: relative;
    z-index: 1;

    margin-bottom: 12rem;
    padding-right: 0;
    padding-left: 0;

    font-size: 24rem;
  }

  .partners__item-text {
    font-size: 12rem;
    line-height: 14.992rem;
  }

  .partners__item-img {
    margin-bottom: 16.4rem;

    &--amkal {
      width: 80.992rem;
      height: 108rem;
    }

    &--nn {
      width: 74.992rem;
      height: 108rem;
    }

    &--parni {
      width: 156.992rem;
      height: 112.992rem;
      margin-right: 62.4rem;
      margin-left: 62.4rem;
    }
  }

  .partners__names {
    flex-grow: 1;

    width: 100%;
    padding-bottom: 4rem;

    border-radius: 5rem;

    &--img {
      position: absolute;

      width: 320rem;
      margin-left: -20rem;

      pointer-events: none;

      .partners__names-list {
        width: 9999rem;
        padding: 0 0 0 20rem;
      }

      .partners__name-text {
        opacity: 0;
      }
    }

    &--title {
      z-index: 1;
    }
  }

  .partners__wrapper--logo {
    //width: 224rem;
    //height: 344rem;
    //75x108
    .partners__name-img {
      width: 75rem;
      height: 108rem;
      margin-bottom: 10rem;
      margin-left: 41rem;
    }

    &::before {
      background: linear-gradient(180deg, #3783b6 0%, #2a93b5 100%);
    }

    &::after {
      background: linear-gradient(180deg, #7040bc 0%, #585eba 100%);
    }
  }

  .partners__names-list {
    @include list-reset;

    display: flex;
    align-items: center;

    margin: 0 -10rem;
  }

  .partners__name-img {
    width: 108rem;
    height: 125rem;
    margin-bottom: 40rem;
    margin-left: 21rem;
  }

  .partners__name-text {
    z-index: 1;

    display: block;

    width: 130rem;
    margin: 0 10rem;
    padding-bottom: 12rem;

    font-size: 12rem;
    line-height: 14.4rem;
  }

  .partners__box {
    bottom: 10rem;

    height: 54rem;

    border: 2rem solid rgba(255, 255, 255, 0.1);
    border-radius: 5rem;
  }
  .partners__wrapper--logo {
    .partners__name-text {
      margin-top: 0;
    }

    .partners__box {
      bottom: 12rem;

      height: 34rem;
    }

    .partners__name-img {
      width: 95rem;
      height: 146rem;
      margin-bottom: -20rem;
      margin-left: 28rem;
    }
  }

  .partners__wrapper--ambassadors {
    .partners__box {
      bottom: 10rem;

      height: 54rem;
    }

    .partners__names {
      padding-bottom: 22rem;
    }

    .partners__name-img {
      width: 108rem;
      height: 125rem;
      margin-bottom: 40rem;
    }
  }
}
