.os-scrollbar {
  --os-size: 8rem;
  --os-track-bg: rgba(255, 255, 255, 0.2);
  --os-track-bg-hover: rgba(255, 255, 255, 0.2);
  --os-track-bg-active: rgba(255, 255, 255, 0.08);
  --os-track-border-radius: 8rem;
  --os-handle-bg: #ffffff;
  --os-handle-bg-hover: #ffffff;
  --os-handle-bg-active: #ffffff;
  --os-padding-perpendicular: 2rem;

  @media screen and (max-width: $lg) {
    --os-size: 6rem;
  }
}
