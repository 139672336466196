.contacts {
  position: relative;
  font-family: 'montserrat', Arial, 'sans-serif';
  font-weight: 900;
  color: white;
  overflow: hidden;
}

.contacts__decor {
  .decor__item--1 {
    top: auto;
    left: auto;
    right: 70rem;
    bottom: 210rem;
  }

  .decor__item--2 {
    bottom: auto;
    top: 150rem;
    left: 70rem;
  }
}

.contacts__container {
  padding-bottom: 160rem;
  padding-top: 110rem;
  padding-left: 333rem;
  padding-right: 333rem;
}

.contacts__title {
  margin-bottom: 72rem;
}

.contacts__list {
  @include list-reset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 94rem;
}

.contacts__item {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 264rem;
}

.contacts__item-name {
  margin: 0;
  margin-bottom: 16rem;
  font-size: 30rem;
  line-height: 1.2;
  text-transform: uppercase;
}

.contacts__item-link {
  font-family: 'montserrat', Arial, sans-serif;
  font-weight: 500;
  font-size: 30rem;
  line-height: 36rem;
  text-decoration-line: underline;
}

.contacts__time {
  text-align: center;
  font-size: 30rem;
  font-weight: 500;
  margin-bottom: 16rem;
}

@media screen and (max-width: $lg) {
  .contacts {
    &:before {
      top: 46rem;
      left: -8rem;
      width: 72rem;
      height: 52.8rem;
      transform: rotate(6.5deg);
    }

    &:after {
      bottom: 190rem;
      right: -20rem;
      width: 72rem;
      height: 52.8rem;
    }
  }
  .contacts__decor {
    .decor__item--1 {
      bottom: 160rem;
      right: -20rem;
      width: 78rem;
      height: 58rem;
    }

    .decor__item--2 {
      top: 40rem;
      left: -10rem;
      width: 73rem;
      height: 54rem;
    }
  }

  .contacts__container {
    padding-top: 62rem;
    padding-bottom: 60rem;
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .contacts__title {
    margin-bottom: 33rem;
    font-size: 24rem;
    display: none;
  }

  .contacts__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 34rem;
  }

  .contacts__item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 30rem;
    }
  }

  .contacts__item-name {
    margin-bottom: 2rem;
    font-size: 20rem;
    line-height: 15rem;
  }

  .contacts__item-link {
    font-size: 14rem;
    line-height: 20rem;
  }

  .contacts__time {
    font-size: 14rem;
    margin-bottom: 10rem;
    line-height: 1;
  }
}
