.faq {
  position: relative;
  background-color: $lavand;
  padding-top: 255rem;
  padding-bottom: 240rem;
  z-index: 1;

  clip-path: polygon(0 140rem, 100% 0, 100% calc(100% - 140rem), 0 100%);
}

.faq__container {
  padding: 0 378rem;
}

.faq__list {
  @include list-reset;
}

.faq__title {
  margin: 0;
  padding: 0;
  margin-bottom: 56rem;
}

.faq__item {
  //padding-left: 12rem;
  border-bottom: 2rem solid #ffffff;

  &:last-child {
    border: none;
  }

  &--active {
    .faq__btn {
      &::before {
        transition: 400ms;
        transform: translateY(-50%) rotate(-0deg);
      }
      &::after {
        transition: 400ms;
        transform: translateX(-50%) rotate(90deg);
      }
    }
  }
}

.faq__item-inner {
  display: flex;
  justify-content: space-between;
  padding-top: 32rem;
  cursor: pointer;
}

.faq__question {
  margin: 0;
  padding: 0;
  margin-bottom: 32rem;
  font-size: 30rem;
  line-height: 36rem;
  font-weight: 700;
}

.faq__btn {
  @include btn-reset;
  position: relative;
  margin-right: 18rem;
  width: 28rem;
  height: 28rem;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transition: 400ms;
    transform: translateY(-50%);
    width: 100%;
    height: 4rem;
    background-color: #ffffff;
  }
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    transition: 400ms;
    transform: translateX(-50%);
    height: 100%;
    width: 4rem;
    background-color: #ffffff;
  }
}

.faq__answer {
  overflow-y: hidden;
  max-height: 0;
  transition: 400ms;
  max-width: 900px;
}

.faq__answer-wrap {
  padding-top: 0;
  padding-bottom: 16rem;
}

.faq__answer-text {
  margin: 0;
  padding: 0;
  font-size: 26rem;
  line-height: 31.7rem;
  span {
    display: block;
    margin-bottom: 8rem;
  }
}

.faq__answer-link {
  text-decoration: underline;
  @media screen and (min-width: 1000px) {
    &:hover {
      text-decoration: none;
    }
  }
}

@media screen and (max-width: $lg) {
  .faq {
    padding-top: 56rem;
    padding-bottom: 56rem;
    clip-path: polygon(0 20rem, 100% 0, 100% calc(100% - 20rem), 0 100%);
  }

  .faq__container {
    padding: 0 20rem;
  }

  .faq__title {
    margin-bottom: 19.2rem;
    font-size: 24rem;
  }

  .faq__item-inner {
    padding-top: 14rem;
    align-items: center;
  }

  .faq__question {
    margin-bottom: 14rem;
    font-size: 16rem;
    line-height: 19.5rem;
    max-width: 250rem;
  }

  .faq__item {
    padding-left: 0;
    border-bottom: 1rem solid #ffffff;
  }

  .faq__btn {
    margin-right: 0;
    margin-top: -12rem;
    width: 19rem;
    height: 19rem;
    &::before {
      height: 2rem;
    }
    &::after {
      width: 2rem;
    }
  }

  .faq__answer-wrap {
    padding-bottom: 16rem;
  }

  .faq__answer-text {
    font-size: 12rem;
    line-height: 14.624rem;
  }
}
