.decor__item {
  position: absolute;

  background-repeat: no-repeat;
  background-size: contain;

  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }

  &:hover::before {
    content: '';
  }
}

.decor__item--1 {
  top: 677.4rem;
  left: 63.6rem;
  width: 172rem;
  height: 128rem;
  background-image: url('../assets/img/arrow-2.svg');

  &::before {
    background: linear-gradient(180deg, $lavand 0%, $lavand 100%);
    filter: blur(105rem);
    box-shadow: 0 0 62rem rgba($lavand, 0.8);
  }
}

.decor__item--2 {
  bottom: 652rem;
  right: 56rem;
  width: 230rem;
  height: 170rem;
  background-image: url('../assets/img/arrow.svg');

  &::before {
    background: linear-gradient(180deg, $cyan 0%, $cyan 100%);
    filter: blur(75rem);
    box-shadow: 0 0 62rem rgba($cyan, 0.8);
    opacity: 0.4;
  }
}

.decor--small {
  .decor__item {
    &::before {
      width: 120%;
      height: 120%;
      background: linear-gradient(180deg, $lavand 0%, $lavand 100%);
      filter: blur(105rem);
      opacity: 1;
    }
  }

  .decor__item--1 {
    top: 310rem;
    left: 125rem;
    width: 144rem;
    height: 107rem;
    background-image: url('../assets/img/arrow.svg');

    &::before {
      background: linear-gradient(180deg, $cyan 0%, $cyan 100%);
    }
  }

  .decor__item--2 {
    bottom: 270rem;
    right: 75rem;
    width: 133rem;
    height: 99rem;
    background-image: url('../assets/img/arrow-2.svg');

    &::before {
      filter: blur(75rem);
    }
  }
}

@media screen and (max-width: $lg) {
  .decor__item--1 {
    top: 342rem;
    left: -4.8rem;
    width: 57.6rem;
    height: 48rem;

    &:hover {
      &:before {
      }
    }
  }

  .decor__item--2 {
    bottom: 513.6rem;
    right: -19.2rem;
    width: 64rem;
    height: 48rem;

    &:hover {
      &:before {
      }
    }
  }

  .decor--small {
    z-index: -1;

    .decor__item--1 {
      top: 32rem;
      left: 10rem;
      width: 74rem;
      height: 55rem;

      &:hover {
        &:before {
        }
      }
    }

    .decor__item--2 {
      bottom: 13rem;
      right: 30rem;
      width: 73rem;
      height: 54rem;

      &:hover {
        &:before {
        }
      }
    }
  }
}
