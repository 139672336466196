.video {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;

  scroll-snap-align: start;

  img {
    width: 100%;
    height: 100%;
  }

  .down {
    bottom: 9.5vh;
    height: 38.788rem;
    width: 46.545rem;
  }
}

.video__logo {
  width: 263rem;
  height: 60rem;
  display: block;
  margin-top: 6vh;
  z-index: 1;
}

.video__deposit {
  margin-top: auto;
  width: 1367rem;
  height: 242rem;
  z-index: 1;
}

.video__btn.share-btn {
  margin-bottom: 9.5vh;
  font-size: 32rem;
  width: 344rem;
  z-index: 1;
  cursor: pointer;
}

.video__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 177.9vh;
  height: 100vh;
  z-index: 0;
}

.video__sources {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .video__wrapper {
    width: 177.9vw;
    height: 100vw;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -25%);
  }
}

@media (max-aspect-ratio: 13/9) {
  .video__deposit {
    width: 965rem;
    margin-bottom: 60rem;
  }
}

@media screen and (min-width: $lg) {
  .video {
    .down {
      position: absolute;
      left: 50%;
    }

    .down.left {
      transform: translate(calc(-50% - 280rem), -25rem);
    }

    .down.right {
      transform: translate(calc(-50% + 280rem), -25rem);
    }
  }
}

@media screen and (max-width: $lg) {
  .video {
    .down {
      display: block;
      bottom: 50rem;
      width: 30.6rem;
      height: 25.3rem;

      &.right {
        display: none;
      }
    }
  }

  .video__logo {
    width: 167rem;
    height: 38rem;
  }

  .video__btn.share-btn {
    font-size: 18rem;
    width: 270rem;
    margin-bottom: 20rem;
  }

  .video__deposit {
    width: 356rem;
    height: 222rem;
    margin-bottom: -30rem;
  }
}
