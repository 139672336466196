//node_module
@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/overlayscrollbars/styles/overlayscrollbars.min';
//@import "node_modules/swiper/core/";

// configs
@import './configs/config';

// universal components
@import './components/buttons';
@import './components/decor';

// sections
@import './components/video';
@import './components/header';
@import './components/promo';
@import './components/advantages';
@import './components/partners';
@import './components/faq';
@import './components/reviews';
@import './components/card';
@import './components/conf';
@import './components/contacts';
@import './components/share-btn';
@import './components/main-popup';
//@import "./components/footer";

// popups
@import './components/popup';

html {
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

.page-wrapper {
  height: 100vh;
  scroll-snap-type: y mandatory;

  min-height: fill-available;
  min-height: -webkit-fill-available;
}

.promo-wrap {
  scroll-snap-align: start;
  @media screen and (min-width: $lg) {
    scroll-snap-align: start;
    //scroll-padding-inline-start: -80rem;
    //scroll-padding-inline-end: -20rem;
  }
}

.video {
  scroll-snap-align: start;
}

.page-inner {
  scroll-snap-align: start;
  box-snap: center;
}
