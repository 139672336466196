@mixin font($fname, $fstyle, $fweight, $furlwoff2, $furlwoff) {
  @font-face {
    font-family: $fname;
    font-style: $fstyle;
    font-weight: $fweight;
    src: url($furlwoff2) format("woff2"), url($furlwoff) format("woff");
  }
}

@include font("montserrat", normal, 900, "../fonts/montserrat-black.woff2",  "../fonts/montserrat-black.woff");
@include font("montserrat", normal, 800, "../fonts/montserrat-extra-bold.woff2",  "../fonts/montserrat-extra-bold.woff");
@include font("montserrat", normal, 700, "../fonts/montserrat-bold.woff2",  "../fonts/montserrat-bold.woff");
@include font("montserrat", normal, 600, "../fonts/montserrat-semibold.woff2",  "../fonts/montserrat-semibold.woff");
@include font("montserrat", normal, 500, "../fonts/montserrat-medium.woff2",  "../fonts/montserrat-medium.woff");


// font-weight helper
// 100 - Extra Light or Ultra Light
// 200 - Light or Thin
// 300 - Book or Demi
// 400 - Regular or Normal
// 500 - Medium
// 600 - Semibold or Demibold
// 700 - Bold
// 800 - Black or Extra Bold or Heavy
// 900 - Extra Black or Fat or Ultra Blac