.conf {
  position: relative;
  padding-top: 110rem;
  background-color: black;

  img,
  picture {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
    margin: 0 auto;
  }
}

.conf__decor {
  z-index: 1;
  .decor__item--1 {
    top: 170rem;
  }

  .decor__item--2 {
    bottom: 130rem;
  }
}

.conf__container {
  padding-bottom: 40rem;
  padding-left: 260rem;
  padding-right: 260rem;
}

.conf__title {
  margin-bottom: 90rem;
}

.conf__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

.conf__item {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 59.2rem;

  &--kk-moskow {
    .conf__item-img {
      height: 323rem;
    }
  }

  &--kk-almaty {
    //display: none;
  }
}

.conf__item-img {
  width: 445rem;
  height: 287rem;
}

@media screen and (max-width: $lg) {
  .conf {
    padding-top: 66rem;
  }

  .conf__container {
    position: relative;
  }

  .conf__decor {
    .decor__item--1 {
      top: 0;
    }

    .decor__item--2 {
      bottom: -10rem;
    }
  }

  .conf__container {
    padding-bottom: 0;
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .conf__title {
    font-size: 24rem;
    margin-bottom: 36.992rem;
  }

  .conf__list {
    max-width: 100%;
  }

  .conf__item {
    margin-bottom: 37rem;

    &--kk-moskow {
      .conf__item-img {
        height: 148rem;
      }
    }

    &--kk-almaty {
      display: block;

      .conf__item-img {
        // height: 148rem;
      }
    }
  }

  .conf__item-img {
    width: 130rem;
    height: auto;

    img {
      height: auto;
    }
  }
}
