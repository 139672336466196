header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 36rem;
  padding-left: 80rem;
  padding-right: 60rem;
  z-index: 1;
}

.nav__list {
  @include list-reset;
  display: flex;
  color: #fff;
  font-size: 20rem;
}

.nav__item {
  padding: 16rem 32rem;
}

.nav__link {
  white-space: nowrap;
}

.logo-wrapper {
  width: 263rem;
  height: 60rem;

  .logo {
    display: block;
    width: 100%;
    height: 100%;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      .logo__ri-color {
        fill: #000000;
      }
    }
  }
}

.header-buttons {
  display: flex;
  align-items: stretch;
  //opacity: 0;
  gap: 20rem;
}

.header__mobile-nav-wrap {
  display: none;
}

@media screen and (max-width: 1400px) {
  .nav__list {
    font-size: 28.8rem;
  }
}

@media screen and (min-width: 1000px) {
  .nav__item {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: $lg) {
  .header {
    height: 77.328rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 20rem;

    &.is-active {
      background-color: #000000;

      svg {
        .logo__ri-color {
          fill: $lavand;
        }
      }
    }
  }

  .logo-wrapper {
    margin-left: 58rem;
    width: 167rem;
    height: 38rem;

    a.logo {
      display: block;
      height: 38rem;
    }

    img,
    picture {
      height: 100%;
    }
  }

  .header__mobile-nav-wrap {
    display: block;

    .header__mobile-menu {
      position: fixed;
      top: 76rem;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 70rem);
      display: flex;
      flex-flow: column;
      background-color: #000000;
      z-index: 1;
      opacity: 0;
      pointer-events: none;

      &.is-active {
        pointer-events: auto;
      }
    }

    .navigation__list {
      @include list-reset;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      height: 116rem;
      font-size: 14rem;
      font-weight: 900;
      margin: 40rem 0;
      text-transform: uppercase;
    }

    .hamburger {
      padding: 16rem;
    }

    .hamburger-box {
      width: 18rem;
      height: 18rem;
      margin-right: -20rem;
    }

    .hamburger-inner {
      margin-top: -1rem;
      width: 18rem;
      height: 2rem;

      &::after,
      &::before {
        width: 18rem;
        height: 2rem;
        border-radius: 4rem;
      }

      &::before {
        top: -7rem;
      }
      &::after {
        bottom: -7rem;
      }
    }
  }

  .header__nav {
    display: none;
  }

  .header-buttons {
    display: flex;

    flex-flow: column;
    align-items: stretch;
    margin: 0;
    gap: 10rem;
    padding-bottom: 20rem;
  }
}
