@mixin retina($density: 2) {
  @media only screen and (min-device-pixel-ratio: $density),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin list-reset {
  margin: 0;
  padding: 0;

  list-style: none;
}

@mixin btn-reset {
  margin: 0;
  padding: 0;

  cursor: pointer;

  border: none;
  outline: none;
  background: none;

  &:focus {
    outline: none;
  }
}

@mixin parallelogram {
  border-radius: 6rem;
  transform: $parallelogram;
}
