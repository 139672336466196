.card {
  position: relative;
  padding-top: 240rem;
  padding-bottom: 208rem;
  background-color: black;
  margin-top: -140rem;
  margin-bottom: -140rem;

  clip-path: polygon(0 140rem, 100% 0, 100% calc(100% - 140rem), 0 100%);

  img,
  picture {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.card__container {
  padding-left: 260rem;
  padding-right: 260rem;
}

.card__title {
  margin-bottom: 96rem;
}

.card__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

.card__item {
  display: flex;
  align-items: center;

  margin-bottom: 32rem;
}

.card__item-img {
  width: 326rem;
  height: 150rem;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &--capitalist,
  &--bitcoin,
  &--tether {
    width: 443rem;
  }
}

@media screen and (max-width: $lg) {
  .card {
    margin: 0;
    padding-top: 80rem;
    padding-bottom: 10rem;
    clip-path: polygon(0 20rem, 100% 0, 100% 100%, 0 100%);
  }

  .card__container {
    position: relative;
    padding-bottom: 50rem;
    padding-left: 20rem;
    padding-right: 20rem;
    z-index: 1;
  }

  .card__title {
    font-size: 24rem;
    margin-bottom: 22rem;
  }

  .card__list {
    max-width: 100%;
  }

  .card__item {
    display: block;
    height: 40rem;
    margin-bottom: 8rem;
  }

  .card__item-img {
    width: 130rem;
    height: 40rem;
  }
}
