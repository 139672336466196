.advantages {
  position: relative;
  padding-top: 66rem;
}

.advantages__container {
  padding-left: 260rem;
  padding-right: 260rem;
  padding-bottom: 56rem;
}

.advantages__title {
  margin-bottom: 80rem;
}

.advantages__list {
  @include list-reset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7.8rem;

  font-family: 'montserrat', Arial, sans-serif;
  font-weight: 500;
  font-size: 26rem;
  line-height: 32rem;
  text-align: center;
  color: white;
}

.advantages__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 76.8rem;
  width: 440rem;
  min-height: 200rem;
}

.advantages__item-text {
  display: block;
  margin: 0;
  min-height: 64rem;
  text-transform: uppercase;
  font-size: 24rem;

  br {
  }
}

.advantages__item-img {
  display: flex;
  justify-content: center;
  width: 100rem;
  height: 100rem;
  margin-bottom: 24rem;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: $lg) {
  .advantages {
    padding-top: 42rem;
    z-index: 10;

    &:before {
      top: 342rem;
      left: -4.8rem;
      width: 57.6rem;
      height: 48rem;
    }

    &:after {
      bottom: 513.6rem;
      right: -19.2rem;
      width: 64rem;
      height: 48rem;
    }
  }

  .advantages__container {
    padding-left: 20rem;
    padding-right: 20rem;
    padding-bottom: 62rem;
  }

  .advantages__title {
    margin-bottom: 38rem;
    font-size: 24rem;
  }

  .advantages__list {
    margin-bottom: 22rem;
  }

  .advantages__item {
    margin-bottom: 81rem;
    min-height: auto;
    justify-content: space-between;
    height: 53rem;
    width: 130rem;
    margin-left: 0;
  }

  .advantages__item-text {
    font-size: 12rem;
    line-height: 15rem;
    min-height: auto;
    max-width: 140rem;
    margin-right: 0;

    br {
      display: block;
    }
  }

  .advantages__item-img {
    margin-bottom: 10rem;
    width: 50rem;
    height: 50rem;
  }
}
