.reviews {
  padding-top: 250rem;
  padding-bottom: 250rem;
  width: 100%;
  background: $background-gradient;
  clip-path: polygon(0 140rem, 100% 0, 100% calc(100% - 140rem), 0 100%);
  user-select: none;
}

.reviews__title {
  margin-bottom: 80rem;
}

.reviews__swiper {
  margin-top: -200rem;
  padding-top: 200rem;
  padding-bottom: 100rem;
}

.reviews__slide {
  position: relative;
  width: 584rem;
  margin-left: 28rem;
  margin-right: 28rem;

  &:first-child {
    margin-left: 20rem;
  }
  &:last-child {
    margin-right: 20rem;
  }
}

.reviews__card {
  position: relative;

  padding: 32rem 16rem 32rem 32rem;
  margin-bottom: 34rem;

  width: 100%;
  height: 444rem;
  background: rgba(217, 217, 217, 0.2);
  border-radius: 9rem;
  border: 3rem solid rgba(255, 255, 255, 0.1);
}

.reviews__text-wrapper {
  position: relative;
  width: 536rem;
  //height: 264rem;
  height: 350rem;
  margin-bottom: 300rem;
  padding-right: 80rem;
  overflow: hidden;
}

.reviews__text {
  font-weight: 500;
  font-size: 24rem;
  line-height: 1.35;
}

.reviews__person {
  display: flex;
  align-items: center;
}

.reviews__person-inner {
  width: 330rem;
}

.reviews__avatar {
  width: 62rem;
  height: 62rem;
  margin-right: 24rem;

  font-weight: 700;
}

.reviews__name {
  margin-bottom: 10rem;
  font-size: 30rem;
  font-weight: 700;
  line-height: 1;
}

.reviews__position {
  font-size: 20rem;
  line-height: 24rem;
}

.reviews__btn-wrapper {
  display: flex;
  position: absolute;
  top: 60rem;
  left: 50%;
  transform: translateX(-50%);
  width: 1400rem;
  justify-content: space-between;
  pointer-events: none;
}

.reviews__scrollbar {
  display: none;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.2);

  .swiper-scrollbar-drag {
    height: 2rem;
    background-color: #ffffff;
  }
}

.reviews__pagination {
  display: flex;
  gap: 24rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%);
  position: absolute;
  height: 13rem;
  width: calc(100vw - 520rem);

  .swiper-pagination-bullet {
    display: inline-block;
    width: 13rem;
    height: 13rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
}

@media screen and (max-width: $lg) {
  .reviews {
    padding-top: 54rem;
    padding-bottom: 50rem;
    clip-path: polygon(0 20rem, 100% 0, 100% calc(100% - 20rem), 0 100%);
  }

  .reviews__title {
    font-size: 24rem;
    margin-bottom: 20rem;
  }

  .reviews__swiper {
    margin-top: 0;
    padding: 0 20rem;
  }

  .reviews__slide {
    width: 100%;
    margin: 0 40rem;

    &:last-child {
      margin-left: 10rem;
    }
  }

  .reviews__card {
    padding: 16rem 6rem 44rem 16rem;
    margin-bottom: 14rem;
    height: 213rem;

    &::before {
      top: 20rem;
      left: 16rem;
      width: 24rem;
      height: 24rem;
    }
  }

  .reviews__text-wrapper {
    width: 244rem;
    height: 180rem;
    margin-bottom: 0;
    padding-right: 10rem;
  }

  .reviews__text {
    font-size: 12rem;
  }

  .reviews__person {
    margin-bottom: 22rem;
  }

  .reviews__person-inner {
    width: 227rem;
  }

  .reviews__avatar {
    width: 41rem;
    height: 41rem;
    margin-right: 12rem;
  }

  .reviews__name {
    font-size: 20rem;
    line-height: 24rem;
    margin-bottom: 3rem;
  }

  .reviews__position {
    font-size: 12rem;
    line-height: 16.2rem;
  }

  .reviews__pagination {
    display: none;
  }

  .reviews__scrollbar {
    display: block;
  }
}
