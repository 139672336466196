.promo {
  position: relative;

  display: flex;
  flex-flow: column;
  flex-grow: 1;
  justify-content: center;

  height: calc(100% - 83.207rem);
  padding-top: 32rem;
  padding-bottom: 15vh;
}

.promo__ambassador {
  position: absolute;
  z-index: -1;

  width: 594.426rem;
  height: 926.059rem;
  max-height: 105vh;

  &--guberniev {
    //bottom: -7.6vh;
    //left: -104.12rem;
    bottom: -10vh;
    left: -193.054rem;

    width: 909rem;
    height: 1362rem;

    background-image: url('../assets/img/promo/guberniev.png');
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: contain;
  }

  &--luxury {
    right: -112rem;
    //bottom: -46.545rem;
    bottom: -18vh;

    width: 827rem;
    height: 1255rem;

    background-image: url('../assets/img/promo/luxury.png');
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: contain;
  }
}

.promo__container {
  max-width: 1056rem;
  margin: 0 auto;
}

.promo__title {
  margin: 0;
  padding: 0 0 2vh;

  text-align: center;
  text-transform: uppercase;

  font-family: 'montserrat', Arial, 'sans-serif';
  font-size: 80rem;
  font-weight: 900;
  line-height: 1.1;
}

.promo__title-award {
  display: block;

  max-width: 415rem;
  margin: 0 auto;
  margin-bottom: 40rem;
}

.promo__offers {
  @include list-reset;

  display: flex;
  justify-content: center;

  margin: 0 -50rem;
  margin-bottom: 6vh;

  text-align: center;

  font-size: 50.424rem;
}

.promo__offers-list {
  margin: 0 55rem;
}

.promo__offers-title {
  display: block;

  margin: 0 48rem 1.5vh;

  font-family: 'montserrat', Arial, 'sans-serif';
  font-size: 26rem;
  font-weight: 900;
}

@media screen and (min-width: $lg) {
  .promo-instruction__stage {
    &:not(:last-child) {
      transition: box-shadow 0.5s ease-in-out;

      &:hover,
      &:focus {
        box-shadow: 0 0 62rem rgba($lavand, 0.8);
      }
    }
  }

  .promo-registration__signon-btn {
    transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;

    &:hover,
    &:focus {
      background-color: lighten($lavand, 5%);
      box-shadow: 0 15.515rem 46.545rem rgba($lavand, 0.8);
    }
  }

  .promo-registration__signin-btn {
    transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;

    &:hover,
    &:focus {
      background-color: lighten($cyan, 5%);
      box-shadow: 0 16rem 46.545rem rgba($cyan, 0.8);
    }
  }

  .promo-registration__rules {
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}

@media screen and (min-width: $lg) and (min-aspect-ratio: 1920/1000) {
  .promo {
    //min-height: calc(100vh - 14rem);
    //padding: 0;
    min-height: 1020rem;
  }
}

@media screen and (max-width: $lg) {
  .promo {
    display: block;

    padding-top: 0;
    padding-bottom: 85rem;
  }

  .promo__container {
    max-width: 100%;
    padding-right: 20rem;
    padding-left: 20rem;
  }

  .promo__offers {
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 0;
    margin-bottom: 15rem;
  }

  .promo__offers-list {
    margin: 0;

    font-size: 14rem;
    line-height: 1;

    &:first-child {
      //margin-right: 56rem;
    }
  }

  .promo__title {
    padding-bottom: 11rem;

    font-size: 24rem;
    line-height: 27.2rem;
  }

  .promo__title-award {
    max-width: 157rem;
    margin-bottom: 15rem;
  }

  .promo__offers-title {
    margin: 0 0 6rem;

    font-size: 14rem;
  }

  .promo__btn {
    padding-bottom: 230rem;
  }

  .promo__btn-link {
    justify-content: center;

    width: 100%;
    padding: 31.04rem;

    font-size: 16rem;
  }

  .promo__ambassador {
    display: none;
  }
}
