$lg: 999.9px;
$sm: 999.9px;

$min: 800rem;

$cyan: #00c7b1;
$lavand: #753bbd;

$hamburger-layer-color: #ffffff;
$hamburger-active-layer-color: #ffffff;
$hamburger-layer-height: 3px;
$hamburger-layer-width: 28px;
$background-gradient: linear-gradient(230.79deg, #753bbd 3.12%, #00c7b1 110.4%);

$ease: all 0.4s ease-in-out;
$parallelogram: skew(-18deg);
$antiparallelogram: skew(18deg);
$hamburger-hover-use-filter: true;
$hamburger-hover-filter: opacity(80%);

@import 'node_modules/hamburgers/_sass/hamburgers/hamburgers';
