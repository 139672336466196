.popup {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;

  display: none;
  overflow: auto;

  width: 100%;
  height: 100%;

  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.774);

  font-size: 20rem;
  &.popup-open {
    display: block;
  }
}

.popup__content {
  max-width: 1700rem;
  padding: 16rem 66rem 66rem;

  cursor: auto;

  border-radius: 16rem;
  background: #000000;
}

.popup__text {
  overflow-x: hidden;
  overflow-y: auto;

  h3 {
    margin-top: 0;
  }
  p {
    margin: 5rem 0;
  }
  ul {
    list-style: none;
  }
  // padding: 0 1rem;

  .hint {
    margin: 20rem 0;

    color: rgba(#ffffff, 0.5);

    font-size: 14rem;
  }

  .actions {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding-top: 15rem;
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 5rem;
    }
    .discount {
      display: inline-block;
      align-self: center;

      padding: 8rem 16rem;

      text-transform: uppercase;

      border: 1px solid #ffffff;
      border-radius: 100rem;

      font-size: 24rem;
    }
    span {
      color: $cyan;

      font-weight: 900;
    }
  }
  strong {
    font-weight: 900;
    text-transform: uppercase;
  }
  a {
    text-decoration: underline;
  }

  .primary {
    padding-top: 20rem;

    color: $cyan;

    font-weight: 900;
  }
}

.popup__container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding-top: 60rem;
}

.simplebar-placeholder {
  display: none;
}

.popup__head {
  position: relative;

  padding: 16rem 0;
  img {
    display: none;
  }

  h2 {
    margin: 0;

    text-align: center;
    text-transform: uppercase;

    font-size: 60rem;
  }
  p {
    margin: 0;
  }
}

.popup__close-btn {
  @include btn-reset;

  position: absolute;
  top: 20rem;
  right: -30rem;

  width: 35rem;
  height: 35rem;

  transform: translateY(-50%);
  &:hover {
    opacity: 0.8;
  }
  &::before,
  &:after {
    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 3.2rem;

    content: '';

    background: #ffffff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &:after {
    // top: auto;
    // bottom: 0;
    transform: rotate(135deg);
  }
}

@media screen and (max-width: $lg) {
  .popup {
    background: #000;
  }
  .popup__content {
    max-width: 100%;
    height: 100%;
    padding: 16rem;
    padding-bottom: 0;

    border-radius: 0;

    font-size: 14rem;
  }

  .popup__container {
    height: 100vh;
    padding-top: 0;
    overflow: auot;
  }

  .popup__head {
    padding-right: 16rem;
    img {
      display: block;
      margin: 0 auto;
      width: 167rem;
      margin-bottom: 40rem;
    }
    h2 {
      font-size: 24rem;
    }
  }
  .popup__text {
    padding-bottom: 0rem;
    .actions {
      flex-flow: column;

      .discount {
        align-self: center;
        align-self: flex-start;

        margin: 20rem 0;

        font-size: 14rem;
      }
    }

    .hint {
      font-size: 12rem;
    }
  }

  .popup__close-btn {
    position: absolute;
    top: 10rem;
    right: 0rem;

    width: 17rem;
    height: 17rem;
    &::before,
    &:after {
      height: 2rem;
    }
  }
}
