.share-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'montserrat', Arial, sans-serif;

  margin: 0 auto;
  width: 422.8rem;
  height: 84rem;

  text-align: center;
  text-transform: uppercase;
  font-size: 30rem;
  line-height: 37rem;
  color: black;

  background-color: $cyan;
  font-weight: 700;
  box-shadow: 0 14rem 28.5rem rgba($cyan, 0.5);
  border-radius: 10rem;
  transform: skew(-18deg);

  span {
    display: inline-block;
    transform: skew(18deg);
  }
  @media screen and (min-width: 1000px) {
    transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;

    &:hover,
    &:focus {
      box-shadow: 0 25rem 50rem rgb($cyan, 0.8);
    }
  }

  @media screen and (max-width: $lg) {
    display: flex !important;
    width: 100%;
    height: 56rem;
    font-size: 20rem;
    line-height: 20rem;
    padding: 0;
  }
}
