.button {
  color: #ffffff;
}
.button--desktop {
  display: block;
}

.button--mobile {
  display: none;
}

.button__download {
  display: flex;
  align-items: center;

  height: 56rem;
  padding: 8rem 24rem;

  border-radius: 999px;

  font-size: 19.394rem;

  gap: 13rem;

  img {
    width: 40rem;
  }

  &--mobile {
    padding: 0;

    background: none;
  }
}

.button__action {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 200rem;
  //height: 54.303rem;
  padding: 17rem 32rem;

  transform: skew(-18deg);
  font-weight: 600;

  color: #000000;
  border-radius: 5rem;
  background-color: $cyan;
  box-shadow: 0 0 55rem rgba(117, 59, 189, 0.6);

  font-size: 19.394rem;
  span {
    transform: skew(18deg);
  }
}

.button__login {
  display: flex;
  align-items: center;
  justify-content: center;

  //width: 170.665rem;
  //height: 54.303rem;
  padding: 16rem 32rem;

  transform: skew(-18deg);
  font-weight: 600;

  color: #ffffff;
  border-radius: 5rem;
  border: 2px solid #fff;
  background-color: rgba(#fff, 0.2);
  box-shadow: 0 0 55rem rgba(117, 59, 189, 0.6);

  font-size: 19.394rem;
  span {
    transform: skew(18deg);
  }
}

.promo__btn {
  display: flex;
  align-items: stretch;
  justify-content: center;

  text-align: center;
  //border-radius: 10rem;
}

.promo__btn-link {
  display: flex;
  padding: 40.727rem 40.727rem;

  text-transform: uppercase;

  color: #000000;
  border-radius: 10rem;
  background-color: $cyan;

  font-size: 29.091rem;
  font-weight: 700;
}

@media screen and (min-width: 1000px) {
  .button__download {
    transition: background-color 0.3s ease-in-out;
    &:hover,
    &:focus {
      transition:
        box-shadow 0.4s ease-in-out,
        background-color 0.3s ease-in-out;

      background-color: rgba(#1a1a1a, 0%);
      box-shadow: 0 0 55.9199rem rgba(117, 59, 189, 0.8);
    }
  }
  .button__login {
    transition:
      box-shadow 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &:hover,
    &:focus {
      box-shadow: 0 0 55.9199px rgba(117, 59, 189, 0.8);
    }
  }

  .header .button--enter {
    &:hover,
    &:focus {
      box-shadow: none;

      a {
        box-shadow: none;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .button--desktop {
    display: none;
  }

  .button--mobile {
    display: block;

    width: 46.545rem;
    height: 46.545rem;
  }

  .button__login,
  .button__action {
    margin: 5rem 30rem;
    padding: 12rem 10rem;
    border-radius: 5rem;
  }

  .button__download {
    justify-content: center;
    &--mobile {
      width: 46.545rem;
      img {
        width: 100%;
      }
    }
  }

  .promo__btn {
    padding: 0;
  }

  .promo__btn-link {
    font-size: 21rem;
    padding: 0;
  }
}
